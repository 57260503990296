.app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-container {
  display: flex;
  height: 100%;
}

.left-panel {
  /* flex: 4;
  display: flex;
  flex-direction: column; */

  position: absolute;
  width: 650px;
  background: #fff;
  height: 100%;
}

.tracker-list-container {
  display: flex;
  height: calc(100vh - 130px);
}

.list-wrapper {
  flex: 2;
  padding: 0 2px 0 20px;
  height: auto;
}

.new-wrapper {
  background-color: #efefef;
  flex: 2;
  padding: 0 10px 0 20px;
  height: auto;
}

.map-wrapper {
  flex: 10;
  height: auto;
}

.line-chart {
  position: absolute;
  right: 0px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 31px -13px;
  padding: 15px;
}

.toggle-button {
  position: absolute;
  right: -50px;
  background: #000;
  color: #fff;
  padding: 10px;
  transform: rotate(90deg);
  transform-origin: center;
  cursor: pointer;
}

.hide {
  transform: translateX(-100%);
}

.show {
  transform: translateX(100%);
}

@media screen and (max-width: 767px) {
  .left-panel {
    height: 34%;
    width: 100%;
  }

  .app-container {
    flex-direction: column-reverse;
  }

  .map-wrapper {
    height: 80vh;
  }

  .tracker-list-container {
    height: calc(35vh - 80px);
  }

  .line-chart {
    display: none;
  }

  .close-button {
    position: absolute !important;
    right: 0;
  }
}