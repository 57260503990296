.list-wrapper {
  height: 100%;
  /* overflow: hidden; */
  overflow-y: auto;
}

/* .list-wrapper:hover {
  overflow-y: auto;
} */

.state-list-name span {
  font-size: 14px;
  font-weight: 600;
}

.state-name {
  font-size: 24px;
  margin-bottom: 20px;
}

.number-of-cases {
  color: #d14f69;
  font-weight: 700;
}

.state-list li {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 13px;
  line-height: 20px;
  /* padding: 10px 16px; */
}

.state-list li:not(:last-child) {
  margin-bottom: 12px;
}

.case-total {
  color: #fff;
  text-align: right;
  font-weight: bold;
  padding: 3px 10px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 49px;
}

.total-confirmed-cases {
  color: #d14f69;
  font-weight: bold;
  line-height: 40px;
  text-align: right;
  padding-right: 10px;
}

.active-case {
  background-color: rgb(244, 195, 99);
}

.recovered-case {
  background-color: #108d90;
}

.death-case {
  background-color: #d14f69;
}

@media screen and (max-width: 767px) {
  .state-name {
    font-size: 20px;
    margin: 20px 0 0 0;
  }
}
