body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

.map-container {
  height: 100%;
}

.leaflet-container {
  z-index: 0;
  height: 100%;
  width: 100%;
}

.information-head {
  position: absolute;
  background: white;
  top: 15px;
  right: 15px;
  padding: 20px;
  max-width: 40vw;
  z-index: 100;
}

@media screen and (min-width: 400px) {
  .information-head {
    font-size: 0.6em;
  }
}

@media screen and (min-width: 800px) {
  .information-head {
    font-size: 0.75em;
  }
}

@media screen and (min-width: 1200px) {
  .information-head {
    font-size: 1em;
  }
}

.switch-text {
  padding: 10px;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.list-content {
  margin-right: 20px;
}

/* Switch CSS begins here */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 1;
  width: 25px;
  height: 25px;
}


/* The slider */
/* .slider {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

*/
/* input:checked+.slider {
  
background-color: #2196f3;
}

 input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders 
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
*/
/* Switch CSS ends here */

/* Button CSS starts */
a.button3 {
  display: inline-block;
  padding: 0.3em 1.2em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #ffffff;
  background-color: #4eb5f1;
  text-align: center;
  transition: all 0.2s;
}

a.button3:hover {
  background-color: #4095c6;
}

@media screen and (max-width: 30em) {
  a.button3 {
    display: block;
    margin: 0.2em auto;
  }
}

/* Button CSS ends */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #108d90;
}