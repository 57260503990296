.app-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 567px) {
  .logo {
    height: 30px;
  }
}
